.rj-prepacksboard {
  transform: translate3d(0, 0, 0);
}
.rj-prepacksboard-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 4;
}
.rj-prepacks-event-name-wrapper {
  display: flex;
  align-items: center;
}
.rj-prepacks-event-team-logo {
  display: inline-block;
  margin-right: 4px;
}
.rj-prepacks-event-team-name,
.rj-prepacks-event-separator,
.rj-prepacks-event-name {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.rj-prepacks-event-name,
.rj-prepacks-event-team-name {
  margin: 0;
}
.rj-prepacks-event-separator {
  margin-right: 4px;
  margin-left: 4px;
}
.rj-prepacks-event-date {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #c5c5c5;
  text-transform: uppercase;
}
.rj-prepacks {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #121212;
  list-style: none;
}
.rj-prepacks-filter {
  box-sizing: border-box;
  padding: 7px 16px;
  border: 1px solid #242424;
  border-radius: 50px;
  color: #ababab;
  background-color: #000;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 300ms ease;
}
.rj-prepacks-filter[aria-selected="true"] {
  border-color: #fff;
  color: #fff;
}
.rj-prepacks-filter:hover {
  color: #fff;
}
button.rj-prepacks-filter {
  font-family: 'Saira Condensed', sans-serif;
}
.rj-prepacks-filters-wrapper {
  position: relative;
  padding: 16px 0;
  background-color: #000;
}
.rj-prepacks-filters-inner {
  display: flex;
  overflow: hidden;
}
.rj-prepacks-filters {
  box-sizing: border-box;
  position: relative;
  display: flex;
  gap: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.rj-prepacks-league .rj-prepack {
  padding: 16px 16px 0 16px;
}
.rj-prepacks-league .rj-prepack::after {
  display: none;
}
.rj-prepacks-view-more {
  display: block;
  padding-top: 16px;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Saira Condensed', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #ababab;
  background-color: #121212;
  text-decoration: none;
}
.rj-prepacks-view-more__arrow {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  width: 10px;
  height: 10px;
}
.rj-prepacks-view-more__arrow:before,
.rj-prepacks-view-more__arrow:after {
  position: absolute;
  content: '';
  background-color: #ababab;
  right: 4px;
  width: 2px;
  height: 6.98267946px;
}
.rj-prepacks-view-more__arrow:before {
  top: -0.31548295px;
  transform: rotate(-45deg);
}
.rj-prepacks-view-more__arrow:after {
  top: 3.27030349px;
  transform: rotate(45deg);
}
.rj-prepack {
  position: relative;
  padding: 16px;
  list-style: none;
}
.rj-prepack::after {
  content: '';
  position: absolute;
  right: 16px;
  bottom: 0;
  left: 16px;
  height: 1px;
  background-color: #242424;
}
sb-lazy-render:last-child .rj-prepack:last-child::after {
  display: none;
}
.rj-prepack__selections {
  position: relative;
  margin-bottom: 16px;
}
.rj-prepack__toggle {
  width: 100%;
  height: 44px;
  font-family: inherit;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background-color: #242424;
  color: #53D337;
  border: 0;
  cursor: pointer;
  position: relative;
}
.rj-prepack__toggle[aria-pressed="true"] {
  background-color: #fff;
  color: #000;
}
.rj-prepack__toggle-odds {
  position: relative;
}
.rj-prepack__toggle--loading {
  position: relative;
  overflow: hidden;
  color: #000;
  background-color: #fff;
}
.rj-prepack__toggle--locked,
.rj-prepack__toggle--suspended {
  position: relative;
  overflow: hidden;
  cursor: default;
  color: #fff;
}
.rj-prepack__toggle--suspended {
  opacity: 0.6;
}
.rj-prepack__toggle-animate-loading {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #DADADA;
  animation: rj-prepack__toggle-animate-loading 1.6s ease-in infinite;
}
@keyframes rj-prepack__toggle-animate-loading {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
.rj-prepack__selection {
  position: relative;
  display: flex;
  align-items: baseline;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.rj-prepack__selection:not(:last-child) {
  margin-bottom: 4px;
}
.rj-prepack__selection:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 12px;
  bottom: -6px;
  left: 7px;
  border-left: 1px dashed #4d4d4d;
}
.rj-prepack__selection-indicator {
  position: absolute;
  top: 3px;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #121212;
  z-index: 1;
}
.rj-prepack__selection-name {
  color: #fff;
  flex-shrink: 0;
}
.rj-prepack__market-name {
  color: #ababab;
}
.rj-prepack__market-name::before {
  content: '';
  display: inline-block;
  margin: 0 5px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ababab;
  vertical-align: middle;
}
.rj-prepack__selection-name-wrapper {
  margin-left: 20px;
}
.rj-prepacks-no-bets {
  margin: 64px auto 40px;
  max-width: 295px;
  text-align: center;
}
.rj-prepacks-no-bets__title {
  margin: 8px 0;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
}
h3.rj-prepacks-no-bets__title {
  font-family: 'Saira Condensed', sans-serif;
}
.rj-prepacks-no-bets__info {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.rj-prepacks-no-bets__button {
  display: inline-block;
  margin: 32px auto 0 auto;
  padding: 8px 16px 8px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  background-color: #53D337;
  color: #121212;
  border: 0;
  border-radius: 4px;
  text-decoration: none;
}
.rj-prepacks-loading-placeholder {
  background-color: #121212;
}
.rj-prepacks-loading-placeholder--league .rj-prepacks-loading-placeholder__component {
  padding-top: 11px;
  padding-bottom: 16px;
}
.rj-prepacks-loading-placeholder__component {
  padding-right: 8px;
  padding-left: 8px;
}
.rj-prepacks-loading-placeholder--event .rj-prepacks-loading-placeholder__component {
  padding-top: 21px;
}
.rj-prepacks-loading-placeholder--event .rj-prepacks-loading-placeholder__component:not(:last-child):after {
  content: "";
  display: block;
  margin-top: 17px;
  height: 1px;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1200ms infinite;
}
.rj-prepacks-loading-placeholder--event .rj-prepacks-loading-placeholder__component:last-child {
  padding-bottom: 16px;
}
.rj-prepacks-loading-placeholder__button {
  height: 44px;
  margin: 22px 0 0;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1200ms infinite;
}
.rj-prepacks-loading-placeholder__rect {
  height: 9px;
  width: 210px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 8px;
  border-radius: 2px;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1200ms infinite;
}
.rj-prepacks-loading-placeholder__rect:not(:first-child) {
  margin-top: 15px;
}
.rj-prepacks-loading-placeholder__event {
  height: 9px;
  width: 210px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 8px;
  border-radius: 2px;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1200ms infinite;
  width: 160px;
}
.rj-prepacks-loading-placeholder__event:not(:first-child) {
  margin-top: 15px;
}
.rj-prepacks-loading-placeholder__separator {
  display: block;
  margin: 12px 0 21px 0;
  height: 1px;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1200ms infinite;
}
@keyframes rj-prepacks-loading-placeholder {
  0% {
    opacity: 30%;
  }
  50% {
    opacity: 70%;
  }
  100% {
    opacity: 30%;
  }
}
.rj-prepacks-header {
  position: relative;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
}
.rj-prepacks-header:last-child::after {
  content: '';
  position: absolute;
  right: 8px;
  bottom: 0;
  left: 8px;
  height: 1px;
  background-color: #242424;
}
.rj-prepacks-header-wrapper {
  background-color: #121212;
}
.rj-prepacks-scoreboard {
  padding-left: 9px;
}
.side-arrow {
  position: absolute;
  top: 50%;
  padding: 3px 0;
  width: 53px;
  height: 32px;
  border: 0;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 1;
}
.side-arrow--left {
  background: linear-gradient(to left, rgba(0, 0, 0, 0), #000000);
  text-align: left;
  left: 0;
}
.side-arrow--right {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #000000);
  text-align: right;
  right: 0;
}
.side-arrow:hover .side-arrow-icon {
  opacity: 1;
}
.side-arrow-icon {
  opacity: 0.6;
}
.rj-prepack-odds-update-indicator {
  pointer-events: none;
  animation: rj-flash-outcome-cell-background 750ms linear;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow {
  position: absolute;
  top: 2px;
  right: 2px;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--up {
  animation: rj-flash-outcome-cell-background 750ms linear both;
  width: 10px;
  height: 10px;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--up:before,
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--up:after {
  position: absolute;
  content: '';
  background-color: #53D337;
  top: 4px;
  height: 2px;
  width: 6.98267946px;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--up:before {
  left: -0.31548295px;
  transform: rotate(-45deg);
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--up:after {
  left: 3.27030349px;
  transform: rotate(45deg);
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--down {
  animation: rj-flash-outcome-cell-background 750ms linear both;
  width: 10px;
  height: 10px;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--down:before,
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--down:after {
  position: absolute;
  content: '';
  background-color: #53D337;
  bottom: 4px;
  height: 2px;
  width: 6.98267946px;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--down:before {
  left: -0.31548295px;
  transform: rotate(45deg);
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--down:after {
  left: 3.27030349px;
  transform: rotate(-45deg);
}
sb-lazy-render {
  display: block;
  position: relative;
  box-sizing: border-box;
}
@keyframes rj-flash-outcome-cell-background {
  0% {
    background-color: #242424;
  }
  50% {
    background-color: #ababab;
  }
  100% {
    background-color: #242424;
  }
}
